import i18n from '@/vue-app/plugins/i18n';
import TYPES from '@/types';
import Vue from 'vue';

// Application
import GetCustomInvestorGoalSearchByCustomerQuery
  from '@/modules/flagship/custom-investor-goal/application/query/get-custom-investor-goal-search-by-customer-query';
import GetRetirementInvestorGoalQuery
  from '@/modules/flagship/retirement-investor-goal/application/queries/get-retirement-investor-goal-query';
import GetIsAvailableToHireStrategyModerateQuery
  from '@/modules/flagship/strategy-moderate/hire/application/queries/get-is-available-to-hire-strategy-moderate-query';

// Domain
import {
  CustomInvestorGoalEntity,
} from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';
import {
  RetirementInvestorGoalDto,
} from '@/modules/flagship/retirement-investor-goal/domain/dtos/retirement-investor-goal-dto';
import {
  AvailableInvestorGoalEntity,
} from '@/modules/flagship/strategy-moderate/hire/domain/entities/availabe-to-hire-strategy-moderate-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type AvailableGoal = {
  investor_goal_id: string;
  label: string;
  icon: string;
  custom_icon_file_id: string;
  updated_at: string;
}

export default class StrategyModerateChooseGoalLinkDialogViewModel {
  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_QUERY)
  private readonly get_retirement_investor_goal_query!: GetRetirementInvestorGoalQuery;

  @Inject(TYPES.GET_CUSTOM_INVESTOR_GOAL_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetCustomInvestorGoalSearchByCustomerQuery;

  @Inject(TYPES.GET_IS_AVAILABLE_TO_HIRE_STRATEGY_MODERATE_QUERY)
  // eslint-disable-next-line max-len
  private readonly get_is_available_to_hire_strategy_moderate!: GetIsAvailableToHireStrategyModerateQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly view!: Vue;

  readonly BUCKET_URL = `${process.env.VUE_APP_S3_PUBLIC_URL}/investment/flagship/custom_investor_icons/`;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.strategy-moderate-choose-link-goal';

  readonly minimum_amount = 1000.00;

  is_loading= false;

  available_goals: Array<AvailableGoal> = [];

  available_goal_ids: Array<AvailableInvestorGoalEntity> = [];

  selected_goal = {
    icon: '',
    investor_goal_id: '',
    label: '',
  };

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )

  get is_disabled() {
    return !this.selected_goal.investor_goal_id || this.is_loading;
  }

  getIconPath = (goal: AvailableGoal) => {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
    let icon = require(`@/assets/icons/custom-goal-icons/${goal.icon}`);
    if (goal.icon === 'icon-add.svg') {
      icon = `${this.BUCKET_URL}${goal.custom_icon_file_id}?${this.getCustomGoalUpdatedAtTimeStamp(goal.updated_at)}`;
    }
    return icon;
  }

  getCustomGoalUpdatedAtTimeStamp = (date: string) => (new Date(date).getTime());

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '28px' : '24px');

  verifyModerateParams = (
    goal: CustomInvestorGoalEntity | RetirementInvestorGoalDto,
    investment_product_wealth_id: string,
  ): boolean => {
    if (
      goal.investor_goal.is_active
        // eslint-disable-next-line max-len
        && goal.investor_goal.associated_product_id === investment_product_wealth_id
        && goal.investor_goal.active_goal_amount
        && goal.investor_goal.active_goal_amount >= this.minimum_amount
    ) {
      if (goal.investor_goal.investment_product_fund_types) {
        const is_available_moderate = goal
          .investor_goal.investment_product_fund_types
          .available.find(
            (available_product) => available_product.name === 'SWS-MODERADO',
          );
        return !!is_available_moderate;
      }
    }
    return false;
  }

  loadRetirementGoal = async () => {
    try {
      const retirement_investor_goal = await this.get_retirement_investor_goal_query.execute();
      const is_available = this.available_goal_ids.find(
        (available_goal) => available_goal.id === retirement_investor_goal.investor_goal_id,
      );
      if (is_available) {
        this.available_goals.push({
          investor_goal_id: retirement_investor_goal.investor_goal.id || '',
          label: this.translate('retirement_goal_name'),
          icon: 'icon-fund.svg',
          custom_icon_file_id: '',
          updated_at: '',
        });
      }
    } catch {
      console.error('Ocurrio un error al cargar la meta Fondo para el retiro');
    }
  }

  loadCustomGoals = async () => {
    const custom_investor_goals = await this.get_search_by_customer_query.execute(true);
    custom_investor_goals.forEach((custom_investor_goal) => {
      const is_available = this.available_goal_ids.find(
        (available_goal) => available_goal.id === custom_investor_goal.investor_goal_id,
      );
      if (is_available) {
        const label = custom_investor_goal.custom_goal_type
          .label !== 'other' ? custom_investor_goal.custom_goal_type
            .label : custom_investor_goal.goal_name;
        this.available_goals.push({
          investor_goal_id: custom_investor_goal.investor_goal.id,
          label,
          icon: custom_investor_goal.custom_goal_type.icon_name,
          custom_icon_file_id: custom_investor_goal.custom_icon_file_id || '',
          updated_at: custom_investor_goal.updated_at || '',
        });
      }
    });
  }

  loadInformationAvailableGoals = async () => {
    try {
      await this.loadRetirementGoal();
      await this.loadCustomGoals();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_moderate_goals'));
    }
  };

  loadAvailableGoalsToLinkWithModerate = async () => {
    try {
      const { investor_goals_with_moderate_investment_product_available } = await this
        .get_is_available_to_hire_strategy_moderate.execute();
      this.available_goal_ids = investor_goals_with_moderate_investment_product_available;
      await this.loadInformationAvailableGoals();
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.hire_strategy_moderate_is_available'));
    }
  }

  showHiringFlowDialog = () => {
    this.view.$emit('showHiringFlowDialog', this.selected_goal.investor_goal_id, this.selected_goal.label);
  }

  initialize = async () => {
    this.view.$emit('loadingStep', true);
    this.is_loading = true;
    await this.loadAvailableGoalsToLinkWithModerate();
    this.is_loading = false;
    this.view.$emit('loadingStep', false);
  }
}
