















































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import StrategyModerateChooseGoalLinkDialogViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/hiring-flow/strategy-moderate-choose-goal-link-dialog-view-model';

@Component({
  name: 'StrategyModerateChooseGoalLinkDialog',
  components: {},
})
export default class StrategyModerateChooseGoalLinkDialog extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  strategy_moderate_choose_goal_link_model = Vue.observable(
    new StrategyModerateChooseGoalLinkDialogViewModel(this),
  );

  closeModal() {
    this.synced_is_open = false;
  }

  start() {
    this.strategy_moderate_choose_goal_link_model.showHiringFlowDialog();
    this.closeModal();
  }

  created() {
    this.strategy_moderate_choose_goal_link_model.initialize();
  }
}
